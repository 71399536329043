<template>
  <div v-if="user && ([1].includes(user.groups[0]) || user.is_superuser)">
    <!-- Botón flotante IA -->
    <template
      v-if="
        show_button_ai &&
        user &&
        (user.user_data == null ||
          (user.user_data && user.user_data.ai_component))
      "
    >
      <b-button v-b-toggle.sidebar-right class="btn-ai noprint" title="IA">
        <img src="../../assets/icon-ai.svg" alt="" class="icon-btn-ai" />
      </b-button>
    </template>

    <!-- Sidebar -->
    <b-sidebar
      id="sidebar-right"
      text-variant="light"
      bg-variant="dark"
      right
      shadow
      z-index="1051"
      width="320px"
      :backdrop="!isPinned"
      backdrop-variant="none"
      :no-close-on-backdrop="isPinned"
      @shown="sidebarOpen"
      @hidden="sidebarClose"
    >
      <!-- Header del Sidebar -->
      <template #header>
        <div class="container-header-sidebar">
          <div>
            <b-icon-x
              variant="light"
              class="mt-1"
              v-b-toggle.sidebar-right
            ></b-icon-x>
            <b-icon-pin
              v-if="!isPinned"
              @click="togglePin"
              class="mt-1"
              scale="0.7"
              v-b-tooltip.v-secondary.top.noninteractive="`Fijar Menú`"
            ></b-icon-pin>
            <b-icon-pin-fill
              v-else
              @click="togglePin"
              class="mt-1"
              scale="0.7"
              v-b-tooltip.v-secondary.top.noninteractive="`Desfijar Menú`"
            ></b-icon-pin-fill>
          </div>

          <h4>Inteligencia Artificial</h4>
        </div>
      </template>

      <!-- Body del Sidebar -->
      <div>
        <b-tabs justified card class="custom-tabs" v-model="active_tab">
          <!-- Pestaña N°1 -->
          <b-tab title="Generador" active>
            <b-card header-tag="header" class="card-options mt-1">
              <template #header>
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="flex-grow-1 text-center m-0">Opciones</h4>
                  <b-icon
                    icon="gear-fill"
                    class="ml-auto icon-settings"
                    @click="advancedSettings"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Opciones Avanzadas'
                    "
                  ></b-icon>
                </div>
              </template>
              <b-card-body
                class="body-card-container"
                :class="{
                  'custom-card-height': sub_option == 2 || sub_option == 3,
                }"
              >
                <div class="text-left">
                  <!-- Selector del Tipo  -->
                  <div
                    class="mb-2"
                    :class="{ 'mt-2': sub_option == 2 || sub_option == 3 }"
                  >
                    <span> ¿Qué desea crear? </span>
                    <v-select
                      id="backdrop-variant"
                      :options="intelligenceOptions"
                      :reduce="(intelligenceOptions) => intelligenceOptions.id"
                      v-model="selected_intelligence_option"
                      :dropdown-should-open="dropdownShouldOpenType"
                      label="label"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="style-chooser mt-2"
                      @input="clearInfoSelector"
                      :disabled="
                        disabled_selected_intelligence_option &&
                        selected_intelligence_option != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <!-- Cada vez que entra el puntero al selector se activa el evento "mouseenter" -->
                      <template #selected-option="{ label }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? label : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ label }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Perfil de Egreso  -->
                  <div
                    v-if="
                      [1, 2, 3, 4, 5, 6].includes(selected_intelligence_option)
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames(
                          "mesh.egressprofile",
                          false,
                          "Perfil de Egreso"
                        )
                      }}:
                    </span>
                    <v-select
                      id="egress-profile-selector"
                      :options="egress_profiles"
                      :reduce="(egress_profiles) => egress_profiles.id"
                      v-model="selected_egress_profile"
                      :dropdown-should-open="dropdownShouldOpenEgressProfile"
                      label="name"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="style-chooser mt-2"
                      @input="fetchEgressProfile"
                      :disabled="
                        disabled_selected_egress_profile &&
                        selected_egress_profile != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <!-- Cada vez que entra el puntero al selector se activa el evento "mouseenter" -->
                      <template #selected-option="{ name }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? name : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ name }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Asignatura  -->
                  <div
                    v-if="
                      [2, 3, 4, 5, 6].includes(selected_intelligence_option)
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames(
                          "mesh.egressprofilematter",
                          false,
                          "Asignatura"
                        )
                      }}:
                    </span>
                    <v-select
                      id="matters-selector"
                      :options="egressProfileMatterOptions"
                      :reduce="
                        (egressProfileMatterOptions) =>
                          egressProfileMatterOptions.id
                      "
                      v-model="selected_egress_profile_matter"
                      :dropdown-should-open="dropdownShouldOpenMatter"
                      label="label"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      @input="fetchStudyUnit"
                      :disabled="
                        disabled_selected_egress_profile_matter &&
                        selected_egress_profile_matter != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <!-- Cada vez que entra el puntero al selector se activa el evento "mouseenter" -->
                      <template #selected-option="{ name }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? name : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ name }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Unidad de Competencia  -->
                  <div
                    v-if="
                      [2, 3].includes(selected_intelligence_option) &&
                      institution &&
                      (institution.internal_use_id == 'ciisa_uss' ||
                        institution.show_competence_unit)
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames(
                          "teaching.tempcompetenceunit",
                          false,
                          "Unidad de competencia"
                        )
                      }}:
                    </span>
                    <v-select
                      id="study-unit-selector"
                      :options="competenceUnitOptions"
                      :reduce="
                        (competenceUnitOptions) => competenceUnitOptions.id
                      "
                      v-model="selected_competence_unit"
                      :dropdown-should-open="dropdownShouldOpenStudyUnit"
                      track-by="id"
                      label="full_sentence"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_competence_unit &&
                        selected_competence_unit != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <!-- Cada vez que entra el puntero al selector se activa el evento "mouseenter" -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? full_sentence : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector RA  -->
                  <div
                    v-if="
                      [3].includes(selected_intelligence_option) ||
                      ([8].includes(selected_intelligence_option) &&
                        sub_option == 4)
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames("teaching.ramicro", false, "RA Micro")
                      }}:
                    </span>
                    <v-select
                      id="study-unit-selector"
                      :options="studyUnitOptions"
                      :reduce="(studyUnitOptions) => studyUnitOptions.id"
                      v-model="selected_study_unit"
                      :dropdown-should-open="dropdownShouldOpenStudyUnit"
                      label="full_sentence"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_study_unit &&
                        selected_study_unit != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? full_sentence : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Dimensión (IE) seleccionada del Nivel de Logro  -->
                  <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 1
                    "
                    class="mb-2"
                  >
                    <span>
                      {{
                        $getVisibleNames("manual.dimension", false, "Dimensión")
                      }}
                      Seleccionado:
                    </span>
                    <v-select
                      id="rubric-achievement-selector"
                      :options="rubricCriteriaOptions"
                      :reduce="
                        (rubricCriteriaOptions) => rubricCriteriaOptions.id
                      "
                      v-model="selected_rubric_criteria"
                      :dropdown-should-open="dropdownShouldOpenRubricCriteria"
                      label="full_sentence"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_rubric_criteria &&
                        selected_rubric_criteria != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? full_sentence : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Nivel de Logro  -->
                  <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 1
                    "
                    class="mb-2"
                  >
                    <span>
                      {{
                        $getVisibleNames(
                          "evaluations2.rubricperformancelevel",
                          false,
                          "Nivel de Logro"
                        )
                      }}
                      Seleccionado:
                    </span>
                    <v-select
                      id="rubric-achievement-selector"
                      :options="rubricAchievementOptions"
                      :reduce="
                        (rubricAchievementOptions) =>
                          rubricAchievementOptions.id
                      "
                      v-model="selected_rubric_achievement"
                      :dropdown-should-open="
                        dropdownShouldOpenRubricAchievement
                      "
                      label="title"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_rubric_achievement &&
                        selected_rubric_achievement != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ title }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? title : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ title }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Dimension Crear / Editar  -->
                  <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 2
                    "
                    class="mb-3"
                  >
                    <span>
                      Seleccione un
                      {{
                        $getVisibleNames(
                          "teaching.evaluationcriteriamicro",
                          false,
                          "Criterio de Evaluación Micro"
                        )
                      }}
                    </span>
                    <v-select
                      id="evaluation-criteria-micro-selector"
                      :options="evaluationCriteriaMicroOptions"
                      :reduce="
                        (evaluationCriteriaMicroOptions) =>
                          evaluationCriteriaMicroOptions.id
                      "
                      v-model="selected_evaluation_criteria_micro"
                      :dropdown-should-open="
                        dropdownShouldOpenEvaluationCriteriaMicro
                      "
                      label="full_sentence"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_evaluation_criteria_micro &&
                        selected_evaluation_criteria_micro != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? full_sentence : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Pregunta  -->
                  <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 3
                    "
                    class="mb-3"
                  >
                    <span>
                      Seleccione un
                      {{
                        $getVisibleNames(
                          "teaching.evaluationcriteriamicro",
                          false,
                          "Criterio de Evaluación Micro"
                        )
                      }}
                    </span>
                    <v-select
                      id="evaluation-criteria-micro-selector"
                      :options="evaluationCriteriaMicroOptions"
                      :reduce="
                        (evaluationCriteriaMicroOptions) =>
                          evaluationCriteriaMicroOptions.id
                      "
                      v-model="selected_evaluation_criteria_micro"
                      :dropdown-should-open="
                        dropdownShouldOpenEvaluationCriteriaMicro
                      "
                      label="full_sentence"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_evaluation_criteria_micro &&
                        selected_evaluation_criteria_micro != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? full_sentence : ''}`,
                            boundary: 'viewport',
                          }"
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 5
                    "
                    class="mb-3"
                  >
                    <span
                      >Se considerará la
                      {{
                        $getVisibleNames(
                          "mesh.competence",
                          false,
                          "Competencia"
                        )
                      }}
                      <strong>
                        {{ order_last_cycle }}.{{ order_last_competence }}
                      </strong>
                      para generar la
                      {{
                        $getVisibleNames(
                          "mesh.competence",
                          false,
                          "Competencia"
                        )
                      }}
                      del
                      {{
                        $getVisibleNames("mesh.cycle", false, "Ciclo Formativo")
                      }}
                      "{{ name_cycle_actual }}"
                    </span>
                  </div>
                  <!-- Selector Competencia  -->
                  <!-- <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 5
                    "
                    class="mb-3"
                  >
                    <span> Seleccione una competencia </span>
                    <v-select
                      id="competence-selector"
                      :options="competenceOptions"
                      :reduce="(competenceOptions) => competenceOptions.id"
                      v-model="selected_competence"
                      :dropdown-should-open="dropdownShouldOpenCompetence"
                      label="full_sentence"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_competence &&
                        selected_competence != null
                      "
                    >
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? full_sentence : ''}`,
                            boundary: 'viewport',
                          }
                          "
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div> -->
                  <!-- Selector Cycle  -->
                  <!-- <div
                    v-if="
                      [8].includes(selected_intelligence_option) &&
                      sub_option == 5
                    "
                    class="mb-3"
                  >
                    <span> Seleccione un ciclo </span>
                    <v-select
                      id="cycle-selector"
                      :options="cycleOptions"
                      :reduce="(cycleOptions) => cycleOptions.id"
                      v-model="selected_cycle"
                      :dropdown-should-open="dropdownShouldOpenCycle"
                      label="name"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_cycle && selected_cycle != null
                      "
                    >
                      <template #selected-option="{ name }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.left.v-secondary.noninteractive="{
                            title: `${showTooltip ? name : ''}`,
                            boundary: 'viewport',
                          }
                          "
                          @mouseenter="checkTextWidth($event)"
                        >
                          {{ name }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div> -->
                  <!-- Texto Libre -->
                  <div
                    v-if="
                      [7].includes(selected_intelligence_option) ||
                      ([8].includes(selected_intelligence_option) &&
                        sub_option == 3)
                    "
                    class="mb-3"
                  >
                    <div
                      v-if="!show_message_evaluation_view"
                      class="mb-3 text-left pre-text-area"
                    >
                      <span v-if="egressProfile">
                        "Del siguiente
                        {{
                          $getVisibleNames(
                            "mesh.egressprofile",
                            false,
                            "Perfil de Egreso"
                          )
                        }}
                        {{ egressProfile.professional_title }}
                        <span v-if="egressProfileMatter">
                          y la
                          {{
                            $getVisibleNames(
                              "mesh.egressprofilematter",
                              false,
                              "Asignatura"
                            )
                          }}
                          {{ egressProfileMatterTrim }} </span
                        >, genera
                        {{ selected_example_amount }}
                        <span v-if="selected_example_amount == 1">
                          ejemplo
                        </span>
                        <span v-else> ejemplos </span> de<span
                          v-if="sub_option == 3"
                        >
                          pregunta{{
                            selected_example_amount == 1 ? "" : "s"
                          }} </span
                        >..."
                      </span>
                    </div>
                    <div
                      v-if="matter && show_message_evaluation_view"
                      class="mb-3 text-left pre-text-area"
                    >
                      "Para la
                      {{
                        $getVisibleNames(
                          "evaluations2.evaluation",
                          false,
                          "Evaluación"
                        )
                      }}
                      de la
                      {{
                        $getVisibleNames(
                          "mesh.egressprofilematter",
                          false,
                          "Asignatura"
                        )
                      }}
                      {{ matterTrim }}, genera
                      {{ selected_example_amount }}
                      <span v-if="selected_example_amount == 1"> ejemplo </span>
                      <span v-else> ejemplos </span>
                      de
                      <span v-if="sub_option == 3">
                        pregunta{{
                          selected_example_amount == 1 ? "" : "s"
                        }} </span
                      >..."
                    </div>

                    <b-form-textarea
                      id="text_area"
                      v-model="text_area"
                      placeholder="Escriba un contexto adicional..."
                      rows="5"
                      max-rows="6"
                      maxlength="300"
                    ></b-form-textarea>
                    <div class="text-right">
                      <span style="font-size: 11pt">
                        Caracteres disponibles:
                        <strong>{{ max_length - text_area.length }}</strong>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Cantidad -->
                <div class="mt-0 text-left">
                  <span>
                    Seleccione la cantidad de ejemplos que desee generar.
                  </span>
                  <div class="container-checkbox-amount mt-1">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="selected_example_amount"
                      :value="1"
                      @change="updateTokens"
                      :disabled="selected_example_amount == 1"
                    >
                      1
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-2"
                      v-model="selected_example_amount"
                      :value="2"
                      @change="updateTokens"
                      :disabled="selected_example_amount == 2"
                    >
                      2
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-3"
                      v-model="selected_example_amount"
                      :value="3"
                      @change="updateTokens"
                      :disabled="selected_example_amount == 3"
                    >
                      3
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-4"
                      v-model="selected_example_amount"
                      :value="4"
                      @change="updateTokens"
                      :disabled="selected_example_amount == 4"
                    >
                      4
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-5"
                      v-model="selected_example_amount"
                      :value="5"
                      @change="updateTokens"
                      :disabled="selected_example_amount == 5"
                    >
                      5
                    </b-form-checkbox>
                  </div>
                </div>
              </b-card-body>
              <b-card-footer>
                <b-button
                  v-if="
                    disabledButtonGenerateExamples ||
                    (user && user.user_data && !user.user_data.ai_component)
                  "
                  variant="primary"
                  size="sm"
                  :disabled="true"
                  >Generar Ejemplos
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  size="sm"
                  @click="generateExamples"
                  :disabled="disabled_button_generate == true"
                  >Generar Ejemplos
                </b-button>
              </b-card-footer>
            </b-card>

            <!-- Opciones Avanzadas -->
            <b-card
              v-if="advanced_settings"
              header-tag="header"
              class="card-options mt-2"
            >
              <template #header>
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="flex-grow-1 text-center m-0">
                    Opciones Avanzadas
                  </h4>
                  <b-icon
                    icon="x"
                    scale="1.2"
                    class="ml-auto icon-settings"
                    @click="advancedSettings"
                    v-b-tooltip.left.v-secondary.noninteractive="
                      'Cerrar Opciones Avanzadas'
                    "
                  ></b-icon>
                </div>
              </template>
              <!-- Body de la Tarjeta -->
              <b-card-body class="body-card-container">
                <!-- Temperatura -->
                <div class="mt-0">
                  <div class="text-left">
                    <div class="container-options-advanced">
                      <span>Temperatura: </span>
                      <b-icon
                        icon="info-circle-fill"
                        scale="1"
                        variant="primary"
                        class="icon-help"
                        v-b-tooltip.hover.v-secondary.left="
                          `Controla la aleatoriedad de los resultados. Valores bajos hacen que las respuestas sean más predecibles, mientras que valores altos generan respuestas más creativas.`
                        "
                      >
                      </b-icon>
                    </div>
                    <b-form-input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      v-model="temperature_value"
                      id="myRange"
                      class="mb-0 pb-0 custom-range"
                      :class="temperatureColorClass"
                    >
                    </b-form-input>
                  </div>
                </div>
                <!-- Tokens Maximos -->
                <div class="mt-0">
                  <div class="text-left">
                    <div class="container-options-advanced">
                      <span>Cantidad de tokens a usar: </span>
                      <b-icon
                        icon="info-circle-fill"
                        scale="1"
                        variant="primary"
                        class="icon-help"
                        v-b-tooltip.hover.v-secondary.left="
                          `Limita el número máximo de tokens (unidad de palabras y fragmentos de palabras) que la respuesta puede contener.`
                        "
                      >
                      </b-icon>
                    </div>
                    <b-form-input
                      type="range"
                      min="150"
                      max="600"
                      v-model="tokens_value"
                      id="myRange"
                      class="mb-0 pb-0 custom-range"
                      :class="tokenColorClass"
                    >
                    </b-form-input>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>

          <!-- Segunda pestaña -->
          <b-tab title="Resultados">
            <!-- Skeleton -->
            <b-card v-if="is_loading && !msg_error" class="mt-1 p-3">
              <div class="d-flex">
                <b-skeleton-icon
                  icon="record-circle-fill"
                  :icon-props="{ fontScale: 1, variant: 'dark' }"
                ></b-skeleton-icon>
                <h5 class="ml-1 text-dark">Generando Ejemplos...</h5>
              </div>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
              <b-skeleton animation="fade" width="90%"></b-skeleton>
            </b-card>
            <b-card
              v-else-if="result_examples.length == 0"
              header-tag="header"
              class="card-options mt-1"
            >
              <template #header>
                <div class="d-flex justify-content-center">
                  <h4>Resultados</h4>
                </div>
              </template>
              <!-- Body de la Tarjeta -->
              <b-card-body class="body-card-container">
                <div class="text-left">
                  <p class="m-0 my-1 p-0">Aún no ha generado ningún ejemplo.</p>
                </div>
              </b-card-body>
            </b-card>

            <!-- Resultados -->
            <b-card
              v-if="result_examples.length > 0"
              header-tag="header"
              class="card-options mt-1"
            >
              <template #header>
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="flex-grow-1 text-center m-0">Resultados</h4>
                  <b-icon
                    icon="clipboard"
                    class="ml-auto icon-settings"
                    @click="copyAllToClipboard()"
                    v-b-tooltip.left.v-secondary.noninteractive="
                      'Copiar todos los Resultados.'
                    "
                  ></b-icon>
                </div>
              </template>
              <!-- Body de la Tarjeta -->
              <b-card-body class="body-card-container">
                <div class="text-left">
                  <!-- Perfil de Egreso -->
                  <div v-if="result_examples.length > 0">
                    <div
                      v-for="(example, index) in result_examples"
                      :key="index"
                      class="position-relative text-justify"
                    >
                      <p
                        @click="copyToClipboard(example, index)"
                        class="copy-effect m-0 mt-1 p-0"
                        v-b-tooltip.v-secondary.noninteractive.hover.top="
                          `Presione el texto para copiar al portapapeles.`
                        "
                      >
                        {{ example }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
            <b-card
              v-if="result_examples.length > 0 && error_message"
              header-tag="header"
              class="card-options mt-3"
            >
              <b-card-body class="body-card-container">
                <div class="text-left">
                  <!-- Perfil de Egreso -->
                  <div v-if="result_examples.length > 0">
                    <div class="mx-1 text-justify">
                      <span> ⚠️{{ error_message }} </span>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { toast } from "@/utils/utils";

export default {
  name: "AiComponent",
  components: {
    vSelect,
  },
  props: {},
  data() {
    return {
      isPinned: false, // Controla si el sidebar está fijado o no
      advanced_settings: false, // Variable para visualizar "Opciones Avanzadas"
      temperature_value: 0.5,
      tokens_value: 300,
      active_tab: 0, // Variable para mostrar los "tabs"
      is_loading: false, // Variable para mostrar "skeletons"
      disabled_button_generate: false, // Variable para deshabilitar boton cuando se genera un ejemplo
      msg_error: false, // Variable para controlar el mensaje de error en el template
      error_message: null,
      copiedExamples: [], // Arreglo para rastrear ejemplos copiados
      parameter_local_storage: false, // Variable para los datos del Local Storage
      selected_intelligence_option: null,
      selected_egress_profile: null,
      selected_egress_profile_matter: null,
      selected_competence_unit: null,
      selected_study_unit: null,
      selected_rubric_criteria: null,
      selected_rubric_achievement: null,
      selected_evaluation_criteria_micro: null,
      selected_competence: null,
      selected_cycle: null,
      name_cycle_actual: "",
      order_last_competence: 0,
      order_last_cycle: 0,
      available_evaluation_criteria_micro: [],
      matter_id: null,
      text_area: "",
      extra_context: "", // Variable para mandar contexto adicional al backend
      selected_example_amount: 3,
      result_examples: [],
      show_button_ai: false,
      disabled_selected_intelligence_option: false,
      disabled_selected_egress_profile: false,
      disabled_selected_egress_profile_matter: false,
      disabled_selected_competence_unit: false,
      disabled_selected_study_unit: false,
      disabled_selected_rubric_criteria: false,
      disabled_selected_rubric_achievement: false,
      disabled_selected_evaluation_criteria_micro: false,
      // disabled_selected_competence: false,
      // disabled_selected_cycle: false,
      disabled_text_area: false,
      showTooltip: false, // Si el texto está truncado devuelve un true
      state_sidebar: false, // Variable para determianr si está abierto o no el sidebar
      modalObserver: null, // Variable para detectar cambios en el DOM
      max_length: 300, // Variable para mostrar cantidad de caracteres usados en el text-area
      show_message_evaluation_view: false, // Variable para mostrar mensaje desde evaluation_view
      extra_label: "", // Variable para dar nombre personalizado a la opcion de (intelligence_options)
      sub_option: 0, // Variable para reutilizar opción 8 en distintos casos
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      show_intelligence_sidebar: names.SHOW_INTELLIGENCE_SIDEBAR,
      filter_intelligence: names.FILTER_INTELLIGENCE,
      intelligence_options: names.INTELLIGENCE_OPTIONS,
      egress_profiles: names.EGRESS_PROFILES,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      competence_units: names.COMPETENCE_UNITS,
      study_units: names.STUDY_UNITS,
      matters: names.MATTERS,
      rules_filter_selector: names.RULES_FILTER_SELECTOR,
      rubric_criterias: names.NEW_RUBRIC_CRITERIAS,
      rubric_achievements: names.NEW_RUBRIC_ACHIEVEMENTS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      competences: names.COMPETENCES,
      cycles: names.CYCLES,
    }),
    intelligenceOptions() {
      let list = this.intelligence_options
        .filter((x) => {
          // Siempre excluye el id 4, excepto en el caso de "ciisa_uss"
          if (
            x.id == 4 &&
            !(
              this.institution &&
              (this.institution.internal_use_id == "ciisa_uss" ||
                this.institution.show_competence_unit)
            )
          ) {
            return false;
          }
          // Incluye id 5 solo si show_ra_macro es true
          if (x.id == 5) {
            return this.institution && this.institution.show_ra_macro == true;
          }
          // Incluye id 6 solo si show_evaluation_criteria_macro es true
          if (x.id == 6) {
            return (
              this.institution &&
              this.institution.show_evaluation_criteria_macro == true
            );
          }
          // Incluye todos los demás ids
          return true;
        })
        .map((x) => {
          let labelOption = false;

          // Si el N° de ejemplos es 1 se coloca en singular
          if (this.selected_example_amount == 1) {
            labelOption = false;
          }
          // Si el N° de ejemplos es 2 3 4 o 5 se coloca en plural
          else if (
            this.selected_example_amount == 2 ||
            this.selected_example_amount == 3 ||
            this.selected_example_amount == 4 ||
            this.selected_example_amount == 5
          ) {
            labelOption = true;
          }
          return {
            ...x,
            label:
              this.$getVisibleNames(x.app_models, labelOption, x.label_text) +
              this.extra_label,
          };
        });
      // Aplica el filtro adicional si está configurado
      if (
        this.rules_filter_selector &&
        this.rules_filter_selector.visible_intelligence_options &&
        this.rules_filter_selector.visible_intelligence_options.length > 0
      ) {
        list = list.filter((x) =>
          this.rules_filter_selector.visible_intelligence_options.includes(x.id)
        );
      }
      return list;
    },
    egressProfileMatterOptions() {
      let list = [];
      if (this.selected_egress_profile == null) return [];
      list = [
        ...this.egress_profile_matters.filter(
          (x) => x.egress_profile == this.selected_egress_profile
        ),
      ];
      list.forEach((x) => {
        const matter = this.matters.find((matter) => matter.id == x.matter);
        if (matter) {
          x.label = String(matter.code + " - " + matter.name);
          x.name = matter.name;
        } else {
          x.label = "";
          x.name = "";
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    competenceUnitOptions() {
      let list = [];
      if (this.selected_egress_profile_matter == null) return [];
      const matter = this.egress_profile_matters.find(
        (x) => x.id == this.selected_egress_profile_matter
      );
      if (matter) {
        list = this.competence_units.filter((x) => x.matter == matter.matter);
      }
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    studyUnitOptions() {
      let list = [];
      if (this.selected_egress_profile_matter == null) return [];
      const matter = this.egress_profile_matters.find(
        (x) => x.id == this.selected_egress_profile_matter
      );
      if (matter) {
        list = this.study_units.filter((x) => x.matter == matter.matter);
      }
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    rubricCriteriaOptions() {
      let list = [];
      if (this.selected_rubric_criteria == null) return [];
      list = this.rubric_criterias.filter(
        (x) => x.id == this.selected_rubric_criteria
      );
      return list;
    },
    competenceOptions() {
      let list = [];
      if (this.selected_competence == null) return [];
      list = this.competences.filter((x) => x.id == this.selected_competence);
      return list;
    },
    cycleOptions() {
      let list = [];
      if (this.selected_cycle == null) return [];
      list = this.cycles.filter((x) => x.id == this.selected_cycle);
      return list;
    },
    rubricAchievementOptions() {
      let list = [];
      if (this.selected_rubric_achievement == null) return [];
      list = this.rubric_achievements.filter(
        (x) => x.id == this.selected_rubric_achievement
      );
      return list;
    },
    evaluationCriteriaMicroOptions() {
      let list = [];
      if (this.available_evaluation_criteria_micro.length == 0) return [];
      list = this.evaluation_criteria_micros.filter((x) =>
        this.available_evaluation_criteria_micro.includes(x.id)
      );
      return list.sort((a, b) =>
        a.full_sentence.localeCompare(b.full_sentence)
      );
    },
    egressProfile() {
      if (!this.selected_egress_profile) return null;
      return this.egress_profiles.find(
        (x) => x.id == this.selected_egress_profile
      );
    },
    egressProfileMatter() {
      if (!this.selected_egress_profile_matter) return null;
      return this.egressProfileMatterOptions.find(
        (x) => x.id == this.selected_egress_profile_matter
      );
    },
    matter() {
      return this.matters.find((x) => x.id == this.matter_id);
    },
    matterTrim() {
      if (!this.matter) return "";
      return this.matter.name.trim();
    },
    egressProfileMatterTrim() {
      if (!this.egressProfileMatter) return "";
      return this.egressProfileMatter.name.trim();
    },
    disabledButtonGenerateExamples() {
      // Opción generar Competencia
      if (
        this.selected_intelligence_option == 1 &&
        this.selected_egress_profile == null
      ) {
        return true;
      }
      // Opción generar Unidad de Estudio
      if (
        this.institution &&
        this.institution.internal_use_id != "duoc_uc" &&
        this.institution.internal_use_id != "ciisa_uss" &&
        this.institution.show_competence_unit == false &&
        this.selected_intelligence_option == 2 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        this.selected_intelligence_option == 2 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        (this.institution.internal_use_id == "ciisa_uss" ||
          this.institution.show_competence_unit) &&
        this.selected_intelligence_option == 2 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_competence_unit == null)
      ) {
        return true;
      }

      // Opción generar Indicador de Logro
      if (
        this.institution &&
        this.institution.internal_use_id != "duoc_uc" &&
        this.institution.internal_use_id != "ciisa_uss" &&
        this.institution.show_competence_unit == false &&
        this.selected_intelligence_option == 3 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_study_unit == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        this.selected_intelligence_option == 3 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_study_unit == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        (this.institution.internal_use_id == "ciisa_uss" ||
          this.institution.show_competence_unit) &&
        this.selected_intelligence_option == 3 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_study_unit == null ||
          this.selected_competence_unit == null)
      ) {
        return true;
      }

      // Opción generar Unidad de Competencia (IPSS)
      if (
        this.selected_intelligence_option == 4 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      // Opción generar RA Macro
      if (
        this.selected_intelligence_option == 5 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      // Opción generar Evaluation Criteria Macro
      if (
        this.selected_intelligence_option == 6 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      // Opción Texto Libre
      if (
        this.selected_intelligence_option == 7 &&
        (this.text_area == null || this.text_area == "")
      ) {
        return true;
      }
      // Opción 8 Generica
      if (this.selected_intelligence_option == 8) {
        if (
          // Sub_Option == 1 Niveles de Logro
          (this.sub_option == 1 &&
            (this.selected_rubric_criteria == null ||
              this.selected_rubric_achievement == null)) ||
          // Sub_Option == 2 Dimension
          (this.sub_option == 2 &&
            this.selected_evaluation_criteria_micro == null) ||
          // Sub_Option == 3 Preguntas
          (this.sub_option == 3 &&
            (this.selected_evaluation_criteria_micro == null ||
              this.text_area == null ||
              this.text_area == "")) ||
          // Sub_Option == 4 Contenidos
          (this.sub_option == 4 && this.selected_study_unit == null) ||
          // Sub_Option == 5 Competencias por ciclos
          (this.sub_option == 5 && this.selected_intelligence_option == null)
        )
          return true;
      }
      return false;
    },
    tokenColorClass() {
      if (this.tokens_value >= 150 && this.tokens_value <= 300) {
        return "tokens-low";
      } else if (this.tokens_value >= 301 && this.tokens_value <= 450) {
        return "tokens-medium";
      } else if (this.tokens_value >= 451 && this.tokens_value <= 600) {
        return "tokens-high";
      } else {
        return "";
      }
    },
    temperatureColorClass() {
      if (this.temperature_value >= 0 && this.temperature_value <= 0.3) {
        return "tokens-low";
      } else if (
        this.temperature_value >= 0.4 &&
        this.temperature_value <= 0.6
      ) {
        return "tokens-medium";
      } else if (this.temperature_value >= 0.7 && this.temperature_value <= 1) {
        return "tokens-high";
      } else {
        return "";
      }
    },
  },
  methods: {
    promptRubricAchievement() {
      if (this.sub_option == 1) {
        let rubric_achiev = this.rubric_achievements.find(
          (x) => x.id == this.selected_rubric_achievement
        );
        let rubric_crit = this.rubric_criterias.find(
          (x) => x.id == this.selected_rubric_criteria
        );
        if (rubric_achiev && rubric_crit) {
          return `Dimensión ${rubric_crit.full_sentence}, Nivel de Logro ${rubric_achiev.title} generame una descripción`;
        } else return null;
      }
      if (this.sub_option == 2) {
        let eva_crit_micro = this.evaluation_criteria_micros.find(
          (x) => x.id == this.selected_evaluation_criteria_micro
        );
        if (eva_crit_micro) {
          return (
            `Guíandote con este Indicador de Logro: ${eva_crit_micro.full_sentence} ` +
            `Solo responde con Indicadores de Evaluación en formato: Verbo, Objeto, Condición. ` +
            `Con verbos de la Taxonomía Marzano y Kendall. Asegúrate de que los verbos estén en tercera persona del singular.`
          );
        }
      }
      if (this.sub_option == 3) {
        let eva_crit_micro = this.evaluation_criteria_micros.find(
          (x) => x.id == this.selected_evaluation_criteria_micro
        );
        if (eva_crit_micro) {
          const matter = this.matterTrim || this.egressProfileMatterTrim;
          return (
            `Asignatura: ${matter}, Guíandote con este Indicador de Logro: ${eva_crit_micro.full_sentence}, ` +
            `Sólo responde con Sugerencias de Preguntas para la Evaluación según el siguiente contexto: ${this.text_area}`
          );
        }
      }
      if (this.sub_option == 4) {
        let content = this.studyUnitOptions.find(
          (x) => x.id == this.selected_study_unit
        );
        if (content) {
          const matter = this.matterTrim || this.egressProfileMatterTrim;
          return (
            `Asignatura: ${matter}, Guíandote con este Resultado de Aprendizaje: ${content.full_sentence}, ` +
            `Sólo responde con Sugerencias de Contenidos`
          );
        }
      }
      if (this.sub_option == 5) {
        let competence = this.competenceOptions.find(
          (x) => x.id == this.selected_competence
        );
        let cycle = this.cycleOptions.find((x) => x.id == this.selected_cycle);

        if (competence && cycle) {
          const competencePluralSingular =
            this.selected_example_amount > 1 ? "Competencias" : "Competencia";

          return (
            `Perfil de Egreso: ${this.egressProfile.name}, Guíandote con esta Competencia: ${competence.full_sentence}, ` +
            `perteneciente al ciclo ${cycle.name}. Sólo responde con ${competencePluralSingular} para el ciclo: ${this.name_cycle_actual}, ` +
            `en formato: Verbo, Objeto, Condición, Contexto, con verbos de niveles 4-6 de la Taxonomía Marzano y Kendall. ` +
            `Asegúrate de que los verbos estén en tercera persona del singular.`
          );
        }
      }
    },
    sidebarOpen() {
      this.state_sidebar = true;
      this.applyModalTransform(); // Aplicar el estilo a los modals ya abiertos
      this.startObservingModals(); // Configurar el observador para detectar nuevos modals
    },
    sidebarClose() {
      this.state_sidebar = false;
      this.resetModalTransform(); // Restablecer el estilo de los modals
      this.stopObservingModals(); // Detener el observador
    },
    applyModalTransform() {
      // Seleccionar todos los modals abiertos y aplicarles el estilo
      const modals = document.querySelectorAll(".modal-open .modal");
      modals.forEach((modal) => {
        if (modal.style.transform != "translateX(-10%)") {
          modal.style.transform = "translateX(-10%)";
          modal.style.transition = "transform 0.3s ease";
        }
      });
    },
    resetModalTransform() {
      // Seleccionar todos los modals abiertos y restablecer el estilo
      const modals = document.querySelectorAll(".modal-open .modal");
      modals.forEach((modal) => {
        modal.style.transform = "";
      });
    },
    startObservingModals() {
      // Crear un observador que detecte cambios solo en los modals
      this.modalObserver = new MutationObserver((mutationsList) => {
        // Solo se activa si el sidebar está abierto
        if (
          this.state_sidebar &&
          mutationsList.some(
            (x) =>
              x.target.classList && x.target.classList.contains("modal-open")
          )
        ) {
          this.applyModalTransform();
        }
      });

      // Observar cambios en el body, pero filtramos solo los cambios relevantes
      this.modalObserver.observe(document.body, {
        childList: true, // Detecta adición y eliminación de nodos
        subtree: true, // Observa todos los descendientes, no solo el body
      });
    },
    stopObservingModals() {
      // Desconectar el observador si está activo
      if (this.modalObserver) {
        this.modalObserver.disconnect();
        this.modalObserver = null;
      }
    },
    dropdownShouldOpenEgressProfile(VueSelect) {
      if (this.egress_profiles.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenType(VueSelect) {
      if (this.intelligenceOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenStudyUnit(VueSelect) {
      if (this.studyUnitOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenRubricAchievement(VueSelect) {
      if (this.rubricAchievementOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenRubricCriteria(VueSelect) {
      if (this.rubricCriteriaOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenEvaluationCriteriaMicro(VueSelect) {
      if (this.evaluationCriteriaMicroOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenCompetence(VueSelect) {
      if (this.competenceOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenCycle(VueSelect) {
      if (this.cycleOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenMatter(VueSelect) {
      if (this.egressProfileMatterOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    disableAiComponent() {
      if (this.user && this.user.user_data != null) {
        this.user.user_data.ai_component = false;
        this.$store.commit("setUser", this.user);
      } else if (this.user && this.user.user_data == null) {
        this.$restful
          .Get(`/authentication/user/${this.user.id}/`)
          .then((response) => {
            this.$store.commit("setUser", response);
          });
      }
      this.$swal.fire({
        title: "Acceso a la IA bloqueado",
        text: "Se ha detectado un uso inapropiado de la IA. Tu acceso será restringido temporalmente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
    generateExamples() {
      this.active_tab = 1;
      this.is_loading = true;
      this.disabled_button_generate = true;
      this.result_examples = [];
      this.msg_error = false;

      // setTimeout(() => {
      //   this.result_examples =
      //     "1. Analizar datos de comportamiento del consumidor, utilizando herramientas de análisis digital, en el contexto de campañas de marketing online.\n\n2. Diseñar estrategias de contenido, aplicando principios de SEO y SEM, en el contexto de la promoción de productos y servicios en plataformas digitales.\n\n3. Gestionar proyectos de marketing digital, implementando metodologías ágiles, en el contexto de la colaboración con equipos multidisciplinarios.".split(
      //       "\n\n"
      //     );
      //   this.is_loading = false; // Desactiva el estado de carga después del retraso
      //   this.disabled_button_generate = false; // Vuelve a habilitar el boton de "Generar Ejemplos"
      // }, 5000);
      let payload = {};
      if (this.selected_egress_profile)
        payload.egress_profile = this.selected_egress_profile;
      if (this.selected_egress_profile_matter)
        payload.egress_profile_matter = this.selected_egress_profile_matter;
      if (this.selected_competence_unit)
        payload.competence_unit = this.selected_competence_unit;
      if (this.selected_study_unit)
        payload.study_unit = this.selected_study_unit;
      if (this.text_area && this.selected_intelligence_option == 7)
        payload.text_area = this.text_area;
      if (this.extra_context && this.selected_intelligence_option == 7)
        payload.extra_context = this.extra_context;
      if (this.selected_intelligence_option)
        payload.inteligence_option = this.selected_intelligence_option;
      if (this.selected_example_amount)
        payload.number_example = this.selected_example_amount;
      if (this.tokens_value) payload.max_tokens = this.tokens_value;
      if (this.temperature_value) payload.temperature = this.temperature_value;
      if (this.matter_id && this.selected_intelligence_option == 7)
        payload.matter = this.matter_id;
      if (this.selected_intelligence_option == 8) {
        const promptRubricAchievement = this.promptRubricAchievement();
        if (promptRubricAchievement == null) return;
        else payload.frontend_prompt = promptRubricAchievement;
      }
      this.$restful
        .Post(`/common/assessment-builder-ai/`, payload)
        .then((response) => {
          this.is_loading = false;
          this.disabled_button_generate = false;

          if (response.content && response.finish_reason)
            this.result_examples = response.content
              .split(/\n{1,}/)
              .filter((part) => part.trim() !== "");

          // Manejo de `finish_reason`
          switch (response.finish_reason) {
            case "length":
              this.error_message =
                "La generación alcanzó el límite de longitud máxima. Los ejemplos mostrados son incompletos.";
              break;
            case "null":
              this.error_message =
                "No se pudo determinar un resultado completo. Por favor, ajusta los parámetros e intenta nuevamente.";
              break;
            case "content_filter":
              this.error_message =
                "Ocurrió un error con la respuesta ya que el contenido se considera inapropiado o sensible.";
              this.disableAiComponent();
              break;
            default:
              this.error_message = null;
          }

          // Uso del Local Storage
          // Parameters AI
          this.$store.commit("setLocalVariable", {
            component: this.$route.name,
            variable: this.urlLocalStorage(),
            value: {
              egress_profile: this.selected_egress_profile,
              egress_profile_matter: this.selected_egress_profile_matter,
              competence_unit: this.selected_competence_unit,
              study_unit: this.selected_study_unit,
              intelligence_option: this.selected_intelligence_option,
              example_amount: this.selected_example_amount,
              temperature_value: this.temperature_value,
              tokens_value: this.tokens_value,
              result_examples: this.result_examples,
              rubric_criteria: this.selected_rubric_criteria,
              rubric_achievement: this.selected_rubric_achievement,
              evaluation_criteria_micro:
                this.selected_evaluation_criteria_micro,
              competence: this.selected_competence,
              cycle: this.selected_cycle,
            },
          });
        })
        .catch(() => {
          this.is_loading = false;
          this.disabled_button_generate = false;
          this.msg_error = true;
          this.result_examples = [
            "Ocurrió un error al generar los ejemplos. Por favor, intenta nuevamente.",
          ];
          this.error_message = "Error de comunicación con el servidor.";
        });
    },
    urlLocalStorage() {
      let arr = [];
      // Opción generar Competencia
      if (this.selected_intelligence_option == 1) {
        arr.push(`competence=ep-${this.selected_egress_profile}`);
      }
      // Opción generar Unidad de Estudio
      if (this.selected_intelligence_option == 2) {
        if (
          this.institution &&
          this.institution.internal_use_id != "duoc_uc" &&
          this.institution.internal_use_id != "ciisa_uss" &&
          this.institution.show_competence_unit == false
        ) {
          arr.push(
            `study_unit=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}`
          );
        }
        if (this.institution && this.institution.internal_use_id == "duoc_uc") {
          arr.push(
            `study_unit=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}`
          );
        }
        if (
          this.institution &&
          (this.institution.internal_use_id == "ciisa_uss" ||
            this.institution.show_competence_unit)
        ) {
          arr.push(
            `study_unit=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}&cu-${this.selected_competence_unit}`
          );
        }
      }
      // Opción generar Indicador de Logro
      if (this.selected_intelligence_option == 3) {
        if (
          this.institution &&
          this.institution.internal_use_id != "duoc_uc" &&
          this.institution.internal_use_id != "ciisa_uss" &&
          this.institution.show_competence_unit == false
        ) {
          arr.push(
            `evaluation_criteria_micro=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}&su-${this.selected_study_unit}`
          );
        }
        if (this.institution && this.institution.internal_use_id == "duoc_uc") {
          arr.push(
            `evaluation_criteria_micro=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}&su-${this.selected_study_unit}`
          );
        }
        if (
          this.institution &&
          (this.institution.internal_use_id == "ciisa_uss" ||
            this.institution.show_competence_unit)
        ) {
          arr.push(
            `evaluation_criteria_micro=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}&cu-${this.selected_competence_unit}`
          );
        }
      }
      // Opción generar Unidad de Competencia (IPSS)
      if (this.selected_intelligence_option == 4) {
        arr.push(
          `competence_unit=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}`
        );
      }
      // Opción generar RA Macro
      if (this.selected_intelligence_option == 5) {
        arr.push(
          `ra_macro=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}`
        );
      }
      // Opción generar Evaluation Criteria Macro
      if (this.selected_intelligence_option == 6) {
        arr.push(
          `evaluation_criteria_macro=ep-${this.selected_egress_profile}&epm-${this.selected_egress_profile_matter}`
        );
      }
      // Opción Generica
      if (this.selected_intelligence_option == 8) {
        if (this.sub_option == 1) {
          arr.push(
            `rubric_achievement=rc-${this.selected_rubric_criteria}&rachv-${this.selected_rubric_achievement}`
          );
        }
        if (this.sub_option == 2) {
          arr.push(`dimension=ecm-${this.selected_evaluation_criteria_micro}`);
        }
        if (this.sub_option == 3) {
          arr.push(`question=ecm-${this.selected_evaluation_criteria_micro}`);
        }
        if (this.sub_option == 4) {
          arr.push(`content=su-${this.selected_study_unit}`);
        }
        if (this.sub_option == 5) {
          arr.push(
            `competence_per_cycle=comp-${this.selected_competence}&cy-${this.selected_cycle}`
          );
        }
      }
      const url = `${arr.join("&")}`;
      return url;
    },
    copyToClipboard(text, index) {
      const textWithoutNumber = text.replace(/^\d+\.\s*/, ""); // Elimina el número al inicio si existe

      navigator.clipboard
        .writeText(textWithoutNumber)
        .then(() => {
          toast("Ejemplo copiado en el portapapeles");
          this.$root.$emit("bv::hide::tooltip"); // Oculta los tooltip del componente para que no se queden fijos al momento de apretar un botón
          this.$set(this.copiedExamples, index, true); // Cambia el icono al 'clipboard-check'
          setTimeout(() => {
            this.$set(this.copiedExamples, index, false); // Cambia el icono al estado original 'clipboard'
          }, 2000);
        })
        .catch((err) => {
          console.error("Error al copiar el texto: ", err);
        });
    },
    copyAllToClipboard() {
      const allExamples = this.result_examples
        .map((example) => example.replace(/^\d+\.\s*/, "")) // Elimina el número inicial si existe
        .join("\n"); // Une los ejemplos con un salto de línea

      navigator.clipboard
        .writeText(allExamples)
        .then(() => {
          toast("Todos los ejemplos han sido copiados al portapapeles");
          this.$root.$emit("bv::hide::tooltip"); // Oculta tooltips si están visibles
        })
        .catch((err) => {
          console.error("Error al copiar los textos: ", err);
        });
    },
    advancedSettings() {
      this.advanced_settings = !this.advanced_settings;
    },
    togglePin() {
      this.isPinned = !this.isPinned; // Cambia entre fijado y no fijado
    },
    updateTokens() {
      if (this.selected_example_amount == 1) {
        this.tokens_value = 160;
      } else if (this.selected_example_amount == 2) {
        this.tokens_value = 220;
      } else if (this.selected_example_amount == 3) {
        this.tokens_value = 300;
      } else if (this.selected_example_amount == 4) {
        this.tokens_value = 360;
      } else if (this.selected_example_amount == 5) {
        this.tokens_value = 460;
      }
    },
    clearInfoSelector() {
      if (this.selected_intelligence_option == 1) {
        this.selected_egress_profile_matter = null;
        this.selected_study_unit = null;
      }
      if (this.selected_intelligence_option == 2) {
        this.selected_study_unit = null;
      }
    },
    fetchEgressProfile() {
      this.selected_egress_profile_matter = null;
      this.selected_study_unit = null;
      if (this.selected_egress_profile) {
        this.$store.dispatch(names.FETCH_MATTERS, {
          egress_profile_id: this.selected_egress_profile,
        });
        this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
          egress_profile_id: this.selected_egress_profile,
        });
      }
    },
    fetchStudyUnit() {
      this.selected_study_unit = null;
      if (this.selected_egress_profile_matter) {
        const matter = this.egress_profile_matters.find(
          (x) => x.id == this.selected_egress_profile_matter
        );
        if (matter) {
          this.$store.dispatch(names.FETCH_STUDY_UNITS, matter.matter);
        }
      }
    },
    checkTextWidth(event) {
      this.$nextTick(() => {
        const element = event.target; // Permite acceder al elemento exacto, sin necesidad de usar refs
        if (element) {
          this.showTooltip = element.scrollWidth > element.clientWidth; // Comparar ancho visible (clientWidth) con el ancho real (scrollWidth)
        }
      });
    },
  },
  watch: {
    show_intelligence_sidebar(value) {
      this.show_button_ai = value;
    },
    filter_intelligence(value) {
      this.active_tab = 0;
      if (value) {
        // Flujo normal cuando recibe valores desde los componentes mediante los "MUTATE"
        switch (value.intelligence_option) {
          case 1: // Opcion 1: Competencia
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            break;
          case 2: // Opcion 2: Unidad de Estudio
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            this.selected_competence_unit = value.competence_unit_id;
            break;
          case 3: // Opcion 3: Indicador de Logro
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            this.selected_competence_unit = value.competence_unit_id;
            this.selected_study_unit = value.study_unit_id;
            break;
          case 4: // Opcion 4: Unidad de Competencia (IPSS)
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            break;
          case 5: // Opcion 5: Resultado de Aprendizaje General
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            break;
          case 6: // Opcion 6: Indicador de Logro General
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            break;
          case 7: // Opcion 7: Opción Libre
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            this.matter_id = value.matter_id;
            this.extra_context = value.extra_context;
            if (value.show_message_evaluation_view == true) {
              this.show_message_evaluation_view =
                value.show_message_evaluation_view;
            }
            this.extra_context = value.extra_context;
            break;
          case 8: // Opcion 8: Nivel de Logro
            this.selected_intelligence_option = value.intelligence_option;
            this.extra_label = value.extra_label;
            this.sub_option = value.sub_option;
            // Variables de los Niveles de Logro
            this.selected_rubric_criteria = value.rubric_criteria_id;
            this.selected_rubric_achievement = value.rubric_achievement_id;
            // Dimensiones Rubrica
            this.selected_evaluation_criteria_micro =
              value.evaluation_criteria_micro;
            this.available_evaluation_criteria_micro =
              value.available_evaluation_criteria_micro;
            this.matter_id = value.matter_id;
            if (value.show_message_evaluation_view == true) {
              this.show_message_evaluation_view =
                value.show_message_evaluation_view;
            }
            // Contenidos del PMD
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            this.selected_study_unit = value.study_unit_id;
            // Variables de la Matriz 1
            this.selected_egress_profile = value.egress_profile_id;
            this.order_last_competence = value.order;
            this.order_last_cycle = value.cycle_order;
            this.selected_cycle = value.cycle_id;
            this.selected_competence = value.competence_id;
            this.name_cycle_actual = value.name_cycle_actual;
        }

        const parameters_ai = this.$store.getters.getLocalVariable(
          this.$route.name,
          this.urlLocalStorage()
        );
        // Valores del LocalStorage
        if (this.parameter_local_storage == false && parameters_ai) {
          this.parameter_local_storage = true;

          // Solo actualiza si son diferentes a los valores en LocalStorage
          if (parameters_ai.egress_profile != this.selected_egress_profile) {
            this.selected_egress_profile = parameters_ai.egress_profile;
          }
          if (
            parameters_ai.egress_profile_matter !=
            this.selected_egress_profile_matter
          ) {
            this.selected_egress_profile_matter =
              parameters_ai.egress_profile_matter;
          }
          if (parameters_ai.competence_unit != this.selected_competence_unit) {
            this.selected_competence_unit = parameters_ai.competence_unit;
          }
          if (parameters_ai.study_unit != this.selected_study_unit) {
            this.selected_study_unit = parameters_ai.study_unit;
          }
          if (
            parameters_ai.intelligence_option !=
            this.selected_intelligence_option
          ) {
            this.selected_intelligence_option =
              parameters_ai.intelligence_option;
          }
          if (parameters_ai.rubric_criteria != this.selected_rubric_criteria) {
            this.selected_rubric_criteria = parameters_ai.rubric_criteria;
          }
          if (
            parameters_ai.rubric_achievement != this.selected_rubric_achievement
          ) {
            this.selected_rubric_achievement = parameters_ai.rubric_achievement;
          }
          if (
            parameters_ai.evaluation_criteria_micro !=
            this.selected_evaluation_criteria_micro
          ) {
            this.selected_evaluation_criteria_micro =
              parameters_ai.evaluation_criteria_micro;
          }
          if (parameters_ai.cycle != this.selected_cycle) {
            this.selected_cycle = parameters_ai.cycle;
          }
          if (parameters_ai.competence != this.selected_competence) {
            this.selected_competence = parameters_ai.competence;
          }
          this.selected_example_amount = parameters_ai.example_amount;
          this.temperature_value = parameters_ai.temperature_value;
          this.tokens_value = parameters_ai.tokens_value;
          this.result_examples = parameters_ai.result_examples;
        }
      }
      // Resetea valores cuando `filter_intelligence` es null
      if (value == null) {
        this.selected_intelligence_option = null;
        this.selected_egress_profile = null;
        this.selected_egress_profile_matter = null;
        this.selected_competence_unit = null;
        this.selected_study_unit = null;
        this.selected_rubric_criteria = null;
        this.selected_rubric_achievement = null;
        this.selected_evaluation_criteria_micro = null;
        this.selected_competence = null;
        this.selected_cycle = null;
        this.parameter_local_storage = false;
        this.show_message_evaluation_view = false;
        this.extra_context = "";
        this.extra_label = "";
        this.sub_option = 0;
        // if (this.user) {
        // localStorage.removeItem(
        //   `${this.$route.name}_parameters_ai_${this.user.id}`
        // );
        // this.selected_example_amount = null;
        // this.temperature_value = null;
        // this.tokens_value = null;
        //}
      }
    },
    rules_filter_selector(value) {
      if (value) {
        if (value.disabled_selected_intelligence_option) {
          this.disabled_selected_intelligence_option =
            value.disabled_selected_intelligence_option;
        }
        if (value.disabled_selected_egress_profile) {
          this.disabled_selected_egress_profile =
            value.disabled_selected_egress_profile;
        }
        if (value.disabled_selected_egress_profile_matter) {
          this.disabled_selected_egress_profile_matter =
            value.disabled_selected_egress_profile_matter;
        }
        if (value.disabled_selected_competence_unit) {
          this.disabled_selected_competence_unit =
            value.disabled_selected_competence_unit;
        }
        if (value.disabled_selected_study_unit) {
          this.disabled_selected_study_unit =
            value.disabled_selected_study_unit;
        }
        if (value.disabled_text_area) {
          this.disabled_text_area = value.disabled_text_area;
        }
        if (value.disabled_selected_rubric_criteria) {
          this.disabled_selected_rubric_criteria =
            value.disabled_selected_rubric_criteria;
        }
        if (value.disabled_selected_rubric_achievement) {
          this.disabled_selected_rubric_achievement =
            value.disabled_selected_rubric_achievement;
        }
        if (value.disabled_selected_evaluation_criteria_micro) {
          this.disabled_selected_evaluation_criteria_micro =
            value.disabled_selected_evaluation_criteria_micro;
        }
        // if (value.disabled_selected_competence) {
        //   this.disabled_selected_competence =
        //     value.disabled_selected_competence;
        // }
        // if (value.disabled_selected_cycle) {
        //   this.disabled_selected_cycle = value.disabled_selected_cycle;
        // }
      }
      if (value == null) {
        this.disabled_selected_intelligence_option = false;
        this.disabled_selected_egress_profile = false;
        this.disabled_selected_egress_profile_matter = false;
        this.disabled_selected_competence_unit = false;
        this.disabled_selected_study_unit = false;
        this.disabled_selected_rubric_criteria = false;
        this.disabled_selected_rubric_achievement = false;
        this.disabled_selected_evaluation_criteria_micro = false;
        // this.disabled_selected_competence = false;
        // this.disabled_selected_cycle = false;
        this.disabled_text_area = false;
      }
    },
  },
  mounted() {
    // Uso del Local Storage
    // Parameters AI
    const parameters_ai = this.$store.getters.getLocalVariable(
      this.$route.name,
      this.urlLocalStorage()
    );

    if (parameters_ai) {
      this.parameter_local_storage = true;
      this.selected_egress_profile = parameters_ai.egress_profile;
      this.selected_egress_profile_matter = parameters_ai.egress_profile_matter;
      this.selected_competence_unit = parameters_ai.competence_unit;
      this.selected_study_unit = parameters_ai.study_unit;
      this.selected_rubric_criteria = parameters_ai.rubric_criteria;
      this.selected_rubric_achievement = parameters_ai.rubric_achievement;
      this.selected_evaluation_criteria_micro =
        parameters_ai.evaluation_criteria_micro;
      this.selected_competence = parameters_ai.competence;
      this.selected_cycle = parameters_ai.cycle;
      this.selected_intelligence_option = parameters_ai.intelligence_option;
      this.selected_example_amount = parameters_ai.example_amount;
      this.temperature_value = parameters_ai.temperature_value;
      this.tokens_value = parameters_ai.tokens_value;
      this.result_examples = parameters_ai.result_examples;
    }
  },
  created() {
    if (this.show_intelligence_sidebar == true)
      this.show_button_ai = this.show_intelligence_sidebar;
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
  },
  beforeDestroy() {
    // Detener el observador al destruir el componente
    this.stopObservingModals();
  },
};
</script>

<style scoped>
[v-tooltip] {
  position: absolute !important;
  z-index: 1060; /* Mayor al sidebar */
}

[v-tooltip].bs-tooltip-start, /* Tooltip hacia la izquierda */
[v-tooltip].tooltip-start {
  transform: translateX(-120%);
}
.sidebar-ai {
  background-color: #2f2f2f !important;
  color: #b4b4b4;
}
.container-header-sidebar {
  display: flex;
}
.btn-ai {
  position: fixed;
  right: 5vw;
  bottom: 0.3vh;
  z-index: 1050;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: #2c3e50;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 1px solid #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-ai:hover {
  background-color: #2c3e50;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}
.label-token-bar {
  border-radius: 5px;
  align-content: center;
  color: white;
  font-weight: bold;
}
.copy-effect:hover {
  background: #d6d6d6;
  cursor: pointer;
}
.pre-text-area {
  font-size: 87.5%;
  color: #212529;
}
.separator {
  border: 1px solid #c9c9c9;
  margin-right: 1rem;
  margin-left: 1rem;
}
.icon-btn-ai {
  width: 40px;
  height: 40px;
}
.card-footer {
  padding: 8px;
}
.card-body {
  padding: 2px;
  min-height: 100%;
}
.card-header {
  padding: 6px;
}
.container-checkbox-amount {
  display: flex;
  justify-content: space-evenly;
}
.card-options {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #2f2f2f;
  background-color: #f8f9fa;
}
.body-card-container {
  max-height: 75vh;
  overflow-y: auto;
}
.custom-card-height {
  min-height: 30vh;
}
.icon-arrow {
  cursor: pointer;
}
.icon-settings {
  cursor: pointer;
}
.container-options-advanced {
  display: flex;
  justify-content: space-between;
}

/* Estilos para el input range */

/* Barra del input */
.custom-range::-webkit-slider-runnable-track {
  height: 6px;
  border-radius: 5px;
  background-color: gray;
}
.custom-range.tokens-low::-webkit-slider-runnable-track {
  /* background-color: #72b6ff; */
  background-color: #72b6ff;
}
.custom-range.tokens-medium::-webkit-slider-runnable-track {
  background-color: #007bff;
}
.custom-range.tokens-high::-webkit-slider-runnable-track {
  background-color: #003267;
}

/* Puntero del Input Range */
.custom-range::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  background: white;
  border: 1px solid #00000073;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px; /* Centrado */
}

/* Estilos para los tabs */
.custom-tabs {
  padding: 5px !important;
}
.custom-tabs >>> .nav-link.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border-color: #007bff;
  padding: 0.4rem;
}

.custom-tabs >>> .nav-link {
  background-color: #ddd;
  color: black;
  border-color: #ddd;
  padding: 0.4rem;
}

div >>> .card-header .card-header-tabs {
  margin-left: -1.325rem !important;
}
/* Estilos relacionados con vue-select */
.style-chooser >>> {
  --vs-controls-color: #212020c0;
  --vs-actions-padding: 0px 4px 0px 0px;
  --vs-dropdown-max-height: 125px;
}
.style-chooser >>> .vs__search {
  padding: 0px !important;
  width: 0px !important;
}
.style-chooser >>> .vs__selected-options {
  /* Quita el espacio en blanco debajo del texto cuando se selecciona una opcion */
  flex-wrap: nowrap;
}
.style-chooser .vs__open-indicator {
  margin-top: 20px;
}
.selected-option-vue-select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}
.b-sidebar {
  overflow: visible !important; /* Asegura que los elementos internos puedan sobresalir */
}

/* Estilos del scrollbar del sidebar */
div >>> .b-sidebar-body::-webkit-scrollbar {
  width: 3px !important;
}
div >>> .b-sidebar-body::-webkit-scrollbar {
  background: #e2e2e2;
}
div >>> .b-sidebar-body::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 8px;
}
div >>> .b-sidebar-body::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* Estilos para el scrollbar de las tarjetas */
::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}
::-webkit-scrollbar-track {
  background: #e2e2e2;
}

::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* Media Querys */
@media only screen and (max-width: 1300px) {
  .btn-ai {
    right: 6.5vw;
  }
}

@media only screen and (max-width: 1100px) {
  .btn-ai {
    right: 7.5vw;
  }
}

@media only screen and (max-width: 900px) {
  .btn-ai {
    right: 9vw;
  }
}
@media only screen and (max-width: 700px) {
  .btn-ai {
    display: none;
  }
}
@media only screen and (max-width: 620px) {
  .btn-ai {
    display: none;
  }
}
</style>